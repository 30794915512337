import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { Playground, Props } from 'docz';
import { Row } from '../components/helpers';
import Facebook from '../../src/Icons/Facebook';
import Instagram from '../../src/Icons/Instagram';
import Pinterest from '../../src/Icons/Pinterest';
import Twitter from '../../src/Icons/Twitter';
import Snapchat from '../../src/Icons/Snapchat';
import Youtube from '../../src/Icons/Youtube';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">




    <h1 {...{
      "id": "icons"
    }}>{`Icons`}</h1>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`import Icon from 'mfcl/Icons/[Icon Name]'
`}</code></pre>
    <Playground __position={0} __code={'<Row>\n  <Facebook />\n  <Instagram />\n  <Pinterest />\n  <Twitter />\n  <Youtube />\n  <Snapchat />\n</Row>\n<br />\n<Row>\n  <Facebook variant=\"red\" />\n  <Instagram variant=\"red\" />\n  <Pinterest variant=\"red\" />\n  <Twitter variant=\"red\" />\n  <Youtube variant=\"red\" />\n</Row>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      Row,
      Facebook,
      Instagram,
      Pinterest,
      Twitter,
      Snapchat,
      Youtube,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <Row mdxType="Row">
    <Facebook mdxType="Facebook" />
    <Instagram mdxType="Instagram" />
    <Pinterest mdxType="Pinterest" />
    <Twitter mdxType="Twitter" />
    <Youtube mdxType="Youtube" />
    <Snapchat mdxType="Snapchat" />
  </Row>
  <br />
  <Row mdxType="Row">
    <Facebook variant='red' mdxType="Facebook" />
    <Instagram variant='red' mdxType="Instagram" />
    <Pinterest variant='red' mdxType="Pinterest" />
    <Twitter variant='red' mdxType="Twitter" />
    <Youtube variant='red' mdxType="Youtube" />
  </Row>
    </Playground>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      